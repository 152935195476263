import React, { useState } from "react";
import Fuse from "fuse.js";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import websiteData from "../../data/websiteData";


const SearchBar = () => {
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const navigate = useNavigate();

    const fuse = new Fuse(websiteData, {
        keys: ["name", "category", "description"],
        threshold: 0.3,
    });

    const handleSearch = (e) => {
        const input = e.target.value;
        setQuery(input);

        if (input.trim() === "") {
            setResults([]);
        } else {
            const searchResults = fuse.search(input).map(({ item }) => item);
            setResults(searchResults);
        }
    };

    const toggleSearchBar = () => {
        setIsSearchVisible(!isSearchVisible);
        setQuery(""); // Clear search on close
        setResults([]);
    };

    const handleResultClick = (link) => {
        toggleSearchBar(); // Close the popup
        navigate(link); // Navigate to the corresponding page
    };

    return (
        <>
            {/* Search Icon */}
                <IoSearch
                    size={28}
                    onClick={toggleSearchBar}
                    className="search-icon"
                />

            {/* Full-Screen Popup */}
            {isSearchVisible && (
                <div className="search-popup">
                    <div className="popup-header">
                        <input
                            type="text"
                            value={query}
                            onChange={handleSearch}
                            placeholder="Search..."
                            className="popup-search-input"
                        />
                        <button className="close-btn" onClick={toggleSearchBar}>
                            x
                        </button>
                    </div>

                    <div className="popup-results">
                        {results.length > 0 ? (
                            <ul className="list-unstyled">
                                {results.map((result, index) => (
                                    <li
                                        key={index}
                                        className="p-3 mb-3 bg-light border rounded shadow-sm"
                                        onClick={() =>
                                            handleResultClick(result.link)
                                        }
                                    >
                                        <h5>{result.name}</h5>
                                        <p>{result.description}</p>
                                    </li>
                                ))}
                            </ul>
                        ) : query.trim() !== "" ? (
                            <p>No results found for "{query}"</p>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default SearchBar;
