import React from "react";
import Layout from "./Layout";

const PrivacyPolicy = () => {
    return (
        <Layout
            title={'Privacy Policy - Citi Pharma Pvt Ltd'}
            description={"Learn about Citi Pharma's commitment to protecting your privacy, how we collect, use, and secure your data, and your rights regarding personal information."}
            keywords={"Citi Pharma, privacy policy, data security, personal information, cookies, data protection, user privacy"}
        >
            <div className="container">
                <h1 className="mb-4 mainHeadings">Privacy Policy</h1>
                <div>
                    <div>
                        <p className="lead">
                            Welcome to Citi Pharma's Privacy Policy. Your privacy is critically
                            important to us.
                        </p>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Information We Collect</h3>
                            <p>
                                We collect information to provide better services to our users. This includes:
                            </p>
                            <ul>
                                <li>Personal identification information (Name, email, phone number, etc.)</li>
                                <li>Usage data including pages visited, time spent, and search queries.</li>
                                <li>Feedback or inquiries submitted through forms.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">How We Use Your Information</h3>
                            <p>We use your information for the following purposes:</p>
                            <ul>
                                <li>To provide and maintain our services.</li>
                                <li>To improve user experience and functionality on our website.</li>
                                <li>To communicate with you, including responding to inquiries or feedback.</li>
                                <li>To send updates, promotional offers, or newsletters.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Sharing Your Information</h3>
                            <p>
                                Citi Pharma does not sell or rent your personal information to third parties.
                                However, we may share information with:
                            </p>
                            <ul>
                                <li>Service providers who assist in operating our website.</li>
                                <li>Legal authorities when required by law or to protect rights.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Cookies and Tracking Technologies</h3>
                            <p>
                                We use cookies to enhance your browsing experience. Cookies allow us to:
                            </p>
                            <ul>
                                <li>Remember your preferences and settings.</li>
                                <li>Understand how you use our website to improve its performance.</li>
                            </ul>
                            <p>
                                You can disable cookies through your browser settings, but doing so may limit
                                some features of the website.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Data Security</h3>
                            <p>
                                Citi Pharma is committed to securing your personal information. We implement
                                industry-standard measures to protect your data from unauthorized access,
                                alteration, disclosure, or destruction.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Third-Party Links</h3>
                            <p>
                                Our website may include links to external sites. Citi Pharma is not responsible
                                for the privacy practices or content of these third-party websites. We recommend
                                reviewing their privacy policies.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Children’s Privacy</h3>
                            <p>
                                Our services are not directed towards children under the age of 13. We do not
                                knowingly collect personal data from children. If you believe your child has
                                provided us with personal information, please contact us.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Your Privacy Rights</h3>
                            <p>
                                Depending on your location, you may have the right to:
                            </p>
                            <ul>
                                <li>Access the personal data we hold about you.</li>
                                <li>Request correction or deletion of your data.</li>
                                <li>Object to certain data processing practices.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Changes to This Privacy Policy</h3>
                            <p>
                                We may update this Privacy Policy periodically. Any changes will be reflected
                                on this page, with the updated date mentioned at the top. Please review it
                                regularly to stay informed about how we protect your data.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Contact Us</h3>
                            <p>
                                If you have any questions or concerns about this Privacy Policy, please contact us at:
                            </p>
                            <ul>
                                <li>Email: info@citipharma.com.pk</li>
                                <li>Phone: +92-123-456789</li>
                                <li>Address: Citi Pharma Pvt Ltd, Lahore, Pakistan</li>
                            </ul>
                        </section>

                        <p className="text-center">
                            Thank you for trusting Citi Pharma. Your privacy matters to us!
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PrivacyPolicy;
