import React from 'react'
import Layout from './Layout'
import Pages from '../../components/Pages'
import { FiPlus } from 'react-icons/fi'

export default function Careers() {
    const handleOpen = () => {
        window.open("https://www.linkedin.com/company/citipharmalimited/jobs/", "_blank")
    }
    return (
        <Layout
            title={"Citi Pharma Careers: Join Our Team"}
            description={'Explore exciting career opportunities at Citi Pharma. Join a leading pharmaceutical company dedicated to innovation, quality, and excellence in healthcare.'}
            keywords={"Citi Pharma careers, job opportunities, pharmaceutical jobs, join Citi Pharma, healthcare careers"}
            image={"images/citi-careers-header.png"}>
            <Pages title={'Careers'} alt={"Citi Pharma Careers"} link={"images/citi-careers-header.png"} />
            <div className="careers">
                <div className="careers-main">
                    <div className="w-100">
                        <h1 className='careers-headings'>Shape Your <br /> <span>Career</span>
                        </h1>
                    </div>
                    <div className="w-100">
                        <div className="card shadow-lg p-3 border-0">
                            We are more than just an office!
                        </div>
                        <div className="card shadow-lg p-3 border-0 my-2">
                            Creating a diversified environment with challenges and rewards
                        </div>
                        <div className="card shadow-lg p-3 border-0">
                            Start your amazing journey with us
                        </div>
                        <button className='btn mt-5' onClick={handleOpen}>View Job Openings <FiPlus className='fs-4' /> </button>
                    </div>
                </div>
                <div className="leads">
                    <div className="leads-text">
                        <h1 className='mainHeadings'>Life at Citi Pharma</h1>
                        <p>Life at Citi Pharma is dynamic, collaborative, and purpose-driven, reflecting our commitment to innovation, growth, and positive impact in healthcare. Our workforce proudly represents the diversity of Pakistan, bringing together talented individuals from different regions to create a vibrant and inclusive culture. We actively champion women empowerment, fostering equal opportunities and encouraging women to thrive in leadership roles. Employees are inspired to take on challenges, contribute ideas, and grow alongside the company. At Citi Pharma, every day is an opportunity to advance pharmaceutical excellence while being part of a diverse, supportive, and inspiring community.</p>
                    </div>
                    <div className="background-image">
                        <img src="/images/citi-careers-02.png" alt="Background" className="" />
                    </div>
                </div>
                <div className="leads">
                    <div className="background-image">
                        <img src="/images/citi-careers-03.png" alt="Background" className="" />
                    </div>
                </div>
                <div className="leads">
                    <div className="background-image">
                        <img src="/images/citi-careers-04.png" alt="Background" className="" />
                    </div>
                    <div className="leads-text">
                        <h1 className='mainHeadings'>Citi Learn and Lead Program</h1>
                        <p>Citi Pharma is proud to introduce the Citi Learn and Lead program, a transformative initiative aimed at nurturing young talent by collaborating with top universities. This program provides students with practical, industry-relevant experiences through paid internships tailored to their educational levels. Undergraduate students can gain valuable insights during a two-month internship, while postgraduate students are offered a comprehensive six-month paid program. With additional efforts underway, this initiative exemplifies Citi Pharma's commitment to empowering the next generation of professionals and strengthening ties between academia and the pharmaceutical industry.</p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
