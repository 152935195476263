import React from 'react'
import Layout from './Layout'
import Pages from '../../components/Pages'

export default function Natural() {
    return (
        <Layout title={'Citi Natural: High-Quality Nutraceuticals'} description={'Citi Natural offers a wide range of nutraceutical products formulated with high-quality ingredients. Explore our tablets, capsules, syrups, creams, and sachets. We also manufacture for local companies.'} keywords={"nutraceuticals, dietary supplements"} image={'images/naturalPage.jpeg'}>
            <Pages title={'Citi Naturals'} alt={"Natural Pharmaceutical Product"} link='images/naturalPage.jpeg' />
            <div className='dev mt-5'>
                <div className="devItem">
                    <h1>Citi Naturals: Your Trusted Source for High-Quality Nutraceuticals</h1>
                    <p>Welcome to Citi Naturals, where innovation meets excellence in the world of nutraceuticals. We are proud to be your trusted partner in delivering high-quality products that promote health and wellness. With a steadfast commitment to quality, innovation, and customer satisfaction, we have established ourselves as a leader in the industry.</p>
                </div>
                <div className="devPara">
                    <div className="devParaBox">
                        <div className='para'>
                            <h1>Exporting Excellence Worldwide</h1>
                            <p>At Citi Naturals, our dedication to quality has earned us recognition on the global stage. We are proud to export our nutraceutical products to prestigious markets such as Dubai, Uzbekistan, and the USA. Our success in these markets is a testament to the superior quality and effectiveness of our products, as well as our unwavering commitment to customer satisfaction.</p>
                            <h1>Local Manufacturing, Global Impact</h1>
                            <p>In addition to our international presence, we are also making a significant impact locally. Citi Naturals is proud to manufacture high-quality nutraceuticals for local companies, contributing to the growth and development of the domestic market. Our state-of-the-art manufacturing facilities and stringent quality control measures ensure that every product that bears the Citi Naturals name meets the highest standards of excellence.</p>
                        </div>
                        <img src="images/naturalPage1.jpg" alt="Herbal Pharmaceutical Product" className='ms-2' style={{height: "270px"}} />
                    </div>
                    <div className="devParaBox">
                        <img src="images/naturalPage2.jpg" alt="Pharmaceutical Product" className='me-2' style={{height: "280px"}} />
                        <div className='para'>
                            <h1>Innovation at the Forefront</h1>
                            <p>At Citi Naturals, innovation is at the heart of everything we do. We are constantly pushing the boundaries of scientific research and development to bring you the latest advancements in nutraceutical technology. Our team of experts is dedicated to developing innovative products that address the evolving needs of our customers and contribute to improved health and wellness.</p>
                            <h1>Wintogeno: A Testament to Quality</h1>
                            <p>One of our flagship products, Wintogeno, is a shining example of our commitment to quality and innovation. Originally developed by Merck, Wintogeno is now proudly manufactured by Citi Naturals for Martindow. This partnership highlights our reputation for excellence and our ability to deliver products of the highest quality to meet the needs of our esteemed partners.</p>
                        </div>
                    </div>
                </div>
                <div className="devItem">
                    <h1>Experience the Citi Naturals Difference</h1>
                    <p>When you choose Citi Naturals, you can trust that you are getting the finest nutraceutical products backed by years of research, expertise, and innovation. Whether you are a global distributor, a local company, or an individual consumer, we are dedicated to providing you with the highest quality products and the best possible service.
                        Thank you for choosing Citi Naturals as your trusted partner in health and wellness. Together, let's embark on a journey towards a healthier, happier tomorrow.</p>
                </div>
            </div>

            <div>
                <div className='w-100 p-5'>
                    <div className="api-table table-responsive">
                        <h1 className='mainHeadings'>Product Syrup Nutraceuticals</h1>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Products Name</th>
                                    <th>Composition</th>
                                    <th>Pack Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>CitIvy Syrup</td>
                                    <td>

                                        Each 5ml contains:-
                                        Hedera Helix (IVY Leaf) Dry Extract (BP).…35mg
                                        Honey (USP)....31.5mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit-couberry Syrup</td>
                                    <td>
                                        Each 5ml contains:-
                                        Hedera Helix (IVY Leaf) Dry Extract (BP).…35mg
                                        Black Elderberry Extract  (USP)....50mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Zinc-Cit Syrup</td>
                                    <td>
                                        Each 5ml contains
                                        Hedera Helix (IVY Leaf) Dry Extract (BP).…35mg Zinc gluconate (BP)....5mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit-Coptis Syrup</td>
                                    <td>
                                        Each ml contains:-
                                        IVY Leaf(Ethanol Dried Extract 30% ) (BP).…2.625mg
                                        Coptis rhizome(Butanol Dried Extract ) (USP).…0.8755mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit-Vitaal Syrup</td>
                                    <td>
                                        Each ml contains:-
                                        Tinospora cordifolia Ext (BP).0.375mg
                                        Piper longum Ext (MS).0.375mg
                                        Cichorium intybus Ext (MS).0.375mg
                                        Terminalia Chebula Ext (USP).0.375mg
                                        Achillea millefolium Ext (BP) 0.375mg
                                        Tribulus terrestris Ext (BP).0.375mg

                                        Emblica officinalis Ext.0.375mg
                                        Elettaria cardamom Ext (BP).0.375mg
                                        Tamrix gallica Ext (MS).0.375mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Mala Glabra Syrup</td>
                                    <td>
                                        Each 5ml contains
                                        Hedera Helix (IVY Leaf) Dry Extract (BP)...35mg
                                        Glycyrrhiza glabra Ext. USP...15mg
                                        Adhatoda vasica Ext. (USP)...10mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>RZIVIK Syrup</td>
                                    <td>
                                        Each 5ml contains
                                        Red Algae Calcium (MS).760mg
                                        Vitamin D3 (USP).400 IU
                                        Zinc (as gluconate) (USP).3mg
                                        Magnesium as (Magnesium sulphate) USP.16mg
                                        Vitamin K2 (MK-7) (USP). 20mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Tusis Go Syrup</td>
                                    <td>
                                        Each 5ml contains:-
                                        Hedera Helix (IVY Leaf) Dry Extract (BP).…35mg
                                        Licorice Ext. (USP)...20mg
                                        Thyme Ext. (BP)...20mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit Ginko Syrup</td>
                                    <td>

                                        Each 5ml contains:-
                                        Hedera Helix (IVY Leaf) Dry Extract (BP).35mg
                                        Panax ginseng (BP)...50mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit Cran C Syrup</td>
                                    <td>

                                        Each 10ml contains
                                        Cranberry Extract (USP)...250mg
                                        Panax ginseng (BP)...50mg Salvia officinalis L. Ext. (Ph. Eur.).50mg
                                        Vitamin C (USP)...20mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit Cran Plus Syrup</td>
                                    <td>
                                        Each 10ml contains
                                        Cranberry Extract (USP)...250mg
                                        Panax ginseng (BP)...50mg
                                        Ginger Ext. (USP)...50mg
                                        Cichorium intybus extract (MS)...15mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Papacit Syrup</td>
                                    <td>
                                        Each 10ml contains
                                        Carica Papaya Leaf Extract (M.S)...275mg</td>
                                    <td>30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Cit-Vit Drops</td>
                                    <td>
                                        Each 1ml contains
                                        Vitamin C  (USP)…12mg
                                        Black Elderberry Extract (USP)…20mg</td>
                                    <td>
                                        10ml
                                        15ml
                                        20ml</td>
                                </tr>
                                <tr>
                                    <td>Gulta-Z Syrup</td>
                                    <td>

                                        Each 10ml contains
                                        L-Glutathione (BP)...400mg
                                        Vitamin C (BP)…90mg
                                        Zinc Gluconate (BP)...69mg</td>
                                    <td>
                                        30ml
                                        60ml
                                        90ml
                                        120ml</td>
                                </tr>
                                <tr>
                                    <td>Fenji Drops</td>
                                    <td>
                                        Each 1ml contains
                                        Foeniculum vulgare Ext. (BP).15mg
                                        Mentha piperita Ext (BP) .10mg
                                        Cuminum cyminum Ext. (M.S).7.5mg
                                        Zingiber officinale Ext. (BP).2.5mg
                                        Ptychotis ajowan Ext. (BP) .0.5mg</td>
                                    <td>10ml
                                        15ml
                                        20ml</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="api-table table-responsive">
                        <h1 className='mainHeadings'>Products Sachet & Creams Nutraceuticals</h1>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Products Name</th>
                                    <th>Composition</th>
                                    <th>Pack Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Citavim Tablet</td>
                                    <td>Each film coated tablet contains:-
                                        Beta-carotene (USP)...0.4 mg  Ascorbic  Acid (USP)...60mg  Vitamin D3 (USF)...0.005mg  (200 IU)
                                        Vitamin E Acetate Dry (USP)...10mg ( 14,9 IU)
                                        Vitamin K1 (USP)...0.030 mg
                                        Thiamine Mononitrate (USP)...1.4 mg
                                        Ribofiavin (USP)...1.6 mg
                                        Niacinamide (USP)...18 mg
                                        Pyridoxine Hydrochloride (USP)...2 mg
                                        Cyanocobalamin (USP)...0.0010 mg
                                        Biotin  (USP)...0.1mg
                                        Calcium Phosphate Dibasic Anhydrous Calcium  (USP)...162 mg
                                        Phosphorus as dibasic sodium phosphate (USP)...125 mg
                                        Cupric Sulfate Anhydrous (USP)...0.5 mg
                                        Ferrous Fumarate (USP)..10mg
                                        Magnesium Oxide (USP).50 mg
                                        Manganese Sulfate Monohydrate (USP)...1 mg
                                        Potassium Chloride (USP)...40mg
                                        Zinc Oxide (USP)...5mg
                                        Folic Acid (USP)...0.195 mg
                                        Lutein(USP)...0.25mg
                                        Calcium D-Pantothenate (USP)...6 mg
                                        Potassium Iodide (USP)...0. 1 mg
                                        Chromic Chloride Hexahydrate (USP)..0.04 mg
                                        Sodium Molybdate (USP)...0.01 mg
                                        Sodium Selenate Anhydrous (USP)...0.03mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Vitamins-Z Tablet</td>
                                    <td>Each film coated tablet contains:-
                                        Ascorbic  Acid...500mg
                                        Nicotinamide (USP)...100mg
                                        Vitamin B1 (USP)...15mg
                                        Vitamin B2 (USP)...15mg
                                        Vitamin B6 (USP)...20mg
                                        Vitamin B12 (USP)...12mcg
                                        Folic Acid (USP)...150mcg
                                        Zinc as Zinc sulphate (USP)...22.5mg
                                        Vitamin E (USP)   30IU
                                        Calcium pantothenate (Pantothenic  Acid) (USP)...20mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>OAD X-citi Tablet</td>
                                    <td>Each film coated tablet contains:-
                                        Horny Goat weed Extract (Icariin Content) (MS)...300mg
                                        L-Arginine HCI (USP)...100mg
                                        Tribulus Terrestris Extract (MS)    250mg
                                        Maca Root Extract (MS)...75mg
                                        Panax Ginseng Extract (USP)...50mg
                                        Muira Pauma Extract (MS)...75mg
                                        Yohimbe Bark Extract (MS)...5mg
                                        Saw palmetto berry powder Extract (USP)...40mg
                                        Ginkgo Biloba Extract (USP)...40mg
                                        Zinc as Zinc oxide (USP)...5mg
                                        Vitamin E ((USP)...30mg
                                        Selenium as Sodium Selenate  Anhydrous (USP)...0.033mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>RedCal Tablet Redmin Tablet</td>
                                    <td>
                                        Each film coated tablet contains:-Red Algae Calcium (MS)...1400mg
                                        Vitamin K2 (MK-7) (USP)...90mcg
                                        Vitamin D 3 (USP)...400 lU
                                        Magnesium as Magnesium oxide (USP)...32mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Jonty-OD Tablet</td>
                                    <td>
                                        Each film coated tablet contains:-
                                        Vitamin A (USP)...600mcg
                                        Vitamin E (USP)...16.75mg
                                        Vitamin D3(USP)...125mcg
                                        Vitamin B7 (Biotin) (USP)...25mcg
                                        Magnesium Oxide (USP).25mg
                                        Zinc Oxide (USP)...10mg
                                        Ginseng extract (USP)...75mg
                                        Collagen (USP)...100mg
                                        Turmeric Extract...150mg
                                        Methylsulfonylmethane (MSM) (USP)...300 mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Gamatin Tabtet</td>
                                    <td>
                                        Each film coated tablet contains:-
                                        Biotin (BP)...50mcg
                                        Chromium picolinate (USP)...400mcg
                                        Magnesium gluconate (BP)...20mg
                                        Pyridoxine HCI (BP)...50mg

                                        Zinc gluconate (BP)...15mg
                                        Potassium chloride (BP)...50mg
                                        Cholecalciferol (BP)...400IU
                                        Folic acid (BP)...400mcg
                                        Niacin (BP)...15mcg
                                        Ascorbic acid (BP)...100mg
                                        Garlic extract (BP)...100mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Kay-cit Tablet</td>
                                    <td>Each film coated tablet contains:-
                                        Vitamin C (USP)...60mg
                                        L-Lysine (USP)…100mg
                                        Trans Resveratrol (USP)..5mg
                                        Vitamin B6 (USP)...12.5mg
                                        Vitamin B9 (Folic acid) (USP)...400mcg
                                        Vitamin B12 (USP)…100mcg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>KIUM-D Tablet</td>
                                    <td>
                                        Each film coated tablet contains:-
                                        Calcium as Calcium carbonate (USP)...500mg
                                        Vitamin D (USP)...400 IU
                                        Vitamin K2 (USP)...90mcg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Polamin Tablet</td>
                                    <td>
                                        Each film costed tablet contains:-
                                        Mecobalamin (USP)...2mg
                                        L-Methylfolate (USP)...3mg
                                        Pyridoxal 5-Phosphate (USP)...35mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Sennocit Tablet</td>
                                    <td>
                                        Each tablet contains:-
                                        Senna leaf extract  (USP)...7.5mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citsip Tablet</td>
                                    <td>
                                        Each film coated tablet contains:-
                                        Vitamin A (USP)...2700IU
                                        Vitamin C (USP)...81mg Vitamin D (USP)...400IU
                                        Vitamin E (USP)...15mg
                                        Thiamine (USP)...1.08mg
                                        Riboflavin (USP)...1.17mg
                                        Niacin (USP)...14.4mg
                                        Vitamin B6 (USP)...1.17mg
                                        Folic Acid (USP)...360mcg
                                        Vitamin B12 (USP)...2.16mcg
                                        Biotin (USP)...27mcg
                                        Pantothenic acid (USP)...4.5mg
                                        Zinc as Zinc sulphate (USP)...11mg
                                        Selenium as sodium selenite (USP)...55mcg
                                        Ttibulus Terrestris Ext. (USP)...100mg
                                        Panax Ginseng Ext. (USP)...25mg
                                        Gingko Biloba Ext. (USP)...30mg
                                        L-Arginine  (USP)...500mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citvit-D Tablet</td>
                                    <td>
                                        Each film coated tablet contains:-
                                        Calcium as Calcium carbonate (USP)...500mg
                                        Vitamin D3 (USP)...600 IU
                                        Vitamin K2 (USP)...90mcg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citomeg Tablet</td>
                                    <td>
                                        Each film coated tablet contains:-
                                        Vitamin A (USP)...150mcg Vitamin C (USP)...100mg Vitamin D3 (USP)...15mcg Vitamin E (USP)...23mg
                                        Thiamine as Thiamine HCI (USP)...20mg
                                        Riboflavin (USP)...20mg
                                        Niacin as Niacinamide (USP)...20mg
                                        Vitamin B6 as Pyridoxine HCI (USP)...10mg
                                        Folate as Folic Acid (USP)...680mcg
                                        Vitamin B12 as Cyanocobalamin (USP).500mcg
                                        Biotin (USP)...1400mcg
                                        Pantothenic acid as Calcium d-pantothenate (USP)...10mg
                                        Iodine as Potassium  iodide (USP)...50mcg
                                        Magnesium as Magnesium oxide (USP)...50mg
                                        Zinc as Zinc oxide (USP).10mg
                                        Selenium as L-Selenomethionine (USP)...50mcg
                                        Copper as Copper gIuconate (USP)...1mg
                                        Manganese  as Manganese sulfate (USP)...2mg
                                        Chromium  as chromium picolinate (USP)...300mcg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Selvit Capsule</td>
                                    <td>
                                        Each Capsule contains:
                                        Tribulus terrestris powder extract (MS)...250mg
                                        Vitamin E acetate(USP).200mg
                                        L-Opti Zinc (zinc mono-L-methionine) (MS)…40mg
                                        Selenium as sodium selenite (USP)0.081mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Optimuno Capsule</td>
                                    <td>
                                        Each Capsule contains:
                                        Tribulus terrestris extract (MS)...250mg
                                        Vitamin E (USP)…100mg
                                        L-Opti Zinc (zinc mono-L-methionine) (MS)…40mg
                                        Selenium as sodium selenite (USP)0.033mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citer Capsule</td>
                                    <td>
                                        Each capsule contains;
                                        Tribulus terrestris Ext (MS)...1500mg
                                        Desmodium  gangeticum Ext. (MS)...500mg
                                        Withania somnifera Ent. (MS)...200mg
                                        Mucuna pruriens Ext. (MS)...150mg
                                        Asparagus racemosus Ext (MS)...30mg
                                        Sida cordifolia Ext. (MS).30mg
                                        Hygrophilla auriculate Ext (MS)...30mg
                                        Argyreia speciose Ext. (MS)...30mg
                                        Myristica fragrans Ext. (MS)...15mg
                                        Asparagus adscendens Ext (MS)...15mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Comnfolia  Capsules</td>
                                    <td>
                                        Each Capsule contains:
                                        Eurycoma Longifolia Extract (MS)...80mg Tribulus Terrestris Extract (MS)...200mg
                                        Cordyceps Sinensis Extract (MS)...50mg
                                        Vitamin E (USP)...32mg
                                        Zinc Oxide (USP)...17mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Enzo-L Capsules</td>
                                    <td>
                                        Each Capsule contuins:
                                        Coenzyme Q10 (USP)…150mg
                                        Alpha Lipoic Acid (USP)...600mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Fencit Capsules</td>
                                    <td>
                                        Each Capsule contains: Fenugreek Extract (MS)...610mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citi-oak Capsules</td>
                                    <td>
                                        Each Capsule contains:
                                        French Oak  Extract (Quercus Robur Wood) (MS)...610mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citilaz Capsule</td>
                                    <td>
                                        Each Capsule contains:
                                        Tumeric Extract (USP)...75mg
                                        Moringa Extract (MS)...75mg
                                        Murraya koenigii extract (MS)    75mg
                                        Niacin (USP)...10mg
                                        Garcina Cambogia Extract (USP)...50mg
                                        Capsimax Fruit Extract (USP)...16.67mg
                                        Piper nigrum cxtract(USP)...2.5mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citogen Capsules</td>
                                    <td>
                                        Each Capsule contains:
                                        Panax Ginseng Extract (USP)...250mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Sapicit Capsule</td>
                                    <td>
                                        Each Capsule contains:
                                        Valerian root extrat (BP)…150mg
                                        Chamomile flower extract (BP)...50mg
                                        Passion flower extract (BP)...30mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citi Enzo Cagsule</td>
                                    <td>
                                        Each Capsule contains: Coenzyme Q10 (USP)...100mg Vitamin E (USP)...75mg
                                        Docosahexaenoic acid (DHA) (USP)…150mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citvit-DM Capsule</td>
                                    <td>
                                        Each Capsule contains:
                                        Vitamin A (USP)...75mcg
                                        Vitamin D3 (USP)...7.5mcg
                                        Vitamin C (USP)...50mg
                                        Vitamin E (USP)...11.5mg
                                        Thiamin (USP)...10mg
                                        Riboftavin (USP)...10mg
                                        Vitamin B6 (USP)...5mg
                                        Folate (as folic acid) USP...200mcg
                                        Vitamin B12 (USP)...250mcg
                                        Biotin (USP)...700mcg
                                        Pantothenic acid  (USP)...5mg
                                        Iodine (as Potassium Iodide) (USP)...25mcg
                                        Magnesium (as oxide) USP...25mg

                                        Zinc (as Oxide) USP...5mg
                                        Selenium(as L-Selenomethionine) (USP)...25mcg
                                        Copper (as Gluconate) USP...0.500mg
                                        Manganese (as sulphate USP...1mg
                                        Chromium(as Picolinate) USP...150mcg
                                        Calcium ( as Carbonate) USP...5mg
                                        Niacin ( as niacinamide) USP...10mg
                                        Alpha Lipoic acid USP...150mg
                                        Gymnema Leaf (Extract) MS...50mg
                                        Organic Green Tea Leaf (Extract) MS..20mg
                                        Lagerstroemia speciosa (Banaba) Leaf (Extract) MS...20mg
                                        Bitter Melon Fruit (Extract)MS...30mg
                                        Organic Turmeric root (Extract) MS...20mg
                                        Beta Glucan MS...50mg
                                        Boron (Boron Citrate) USP...1mg
                                        Vanadium (Vanadyl  sulfate) USP...300mcg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>G-Cit Capeale</td>
                                    <td>
                                        Each Capsule contains:
                                        Ginkgo biloba Extract (BP)…120mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citicar Capsule</td>
                                    <td>
                                        Each Capsule contains: Coenzymc Q 10 (USP)…100mg Vitamin E(USP)…10mg
                                        Docosahexaenoic acid (DHA) (USP)…150mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citig Capsule</td>
                                    <td>
                                        Each Capsule contains:
                                        Maca Extract (MS)...200mg
                                        Hornygoat  weed Extract (MS)...60mg
                                        L-Arginine HCl (USP)...150mg
                                        Rhodiola extract (MS)...100mg
                                        Muira Puama Extract (MS)...60mg
                                        Ashwaghanda Extract (USP)...50mg
                                        Powdered Ginseng Extract (BP)...50mg
                                        Oat Straw Extract (MS)...40mg
                                        Ginkgo Biloba Extract (BP)...30mg
                                        Saw Palmetto Extract (BP)...10mg
                                        Zinc Oxide (USP)...5mg</td>
                                    <td>10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="api-table table-responsive">
                        <h1 className='mainHeadings'>Product Tablet & Capusles Nutraceuticals</h1>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Products Name</th>
                                    <th>Composition</th>
                                    <th>Pack Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Citi's Hush Sachet</td>
                                    <td>
                                        Each sachet contains:
                                        Psyllium husk (B.P)...4g</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Vitamina-Z Sachet</td>
                                    <td>

                                        Each sachet contains:
                                        Vitamin C  (USP)…100mg
                                        Vitamin D3 (USP)...800IU
                                        Vitamin E  (USP)...13.5mg
                                        Zinc sulphate eq. to Elemental Zinc (USP)…12.5mg
                                        Vitamin B6 (USP)...2mg
                                        Vitamin B7  (USP)…150mcg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Magno Sachet</td>
                                    <td>
                                        Each sachet contains:
                                        Magnesium (oxide) USP...400mg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Jonty Sachet</td>
                                    <td>
                                        Each 5g Effervescent  sachet
                                        contains
                                        Calcium carbonate eq. to Elemental
                                        Calcium (USP)...500 mg
                                        Vitamin K2 (USP)...90 mcg Vitamin D3(USP)...600 IU
                                        Vitamin C(USP)…80 IU</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Conecto Sachet</td>
                                    <td>
                                        Each Sachet contains
                                        Collagen (USP)...10gm
                                        Calcium Citrate Malate (USP)…1000mg
                                        Vitamin D3 (USP)...400 I.U</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Citi's Collagen Sachet</td>
                                    <td>
                                        Each Sachet contains
                                        Fish collagen (MS)…4gm
                                        Curcuma longa extract
                                        (MS)...250mg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Ovolic Sachet</td>
                                    <td>
                                        Each 5g sachet contains:
                                        Myo Inositol(BP)…1150mg
                                        D-Chiro Inositol (USP)…150mg
                                        Folic Acid (BP)…200mcg
                                        Chromium Piclinate(USP)…100mcg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Multicit Sachet</td>
                                    <td>
                                        Each 10g sachet contain
                                        Vitamin A(BP)...1000 IU
                                        Vitamin C(BP)...1000 mg
                                        Vitamin B3(BP)...5mg
                                        Viiamin B6(BP)...2mcg
                                        Vitainin B12(BP)...25mcg
                                        Pantothenic  Acid(BP)...2.5mg
                                        Calcium(as calcium gluconate)
                                        (BP)...50 mg Phosphorus(Tricalcium phosphate)(BP)...38 mg
                                        Iodine(As  potassium iodide)(BP)...150 mcg
                                        Magnesium  oxide(BP)...30mg
                                        Zinc gluconate(BP)...5 mg
                                        Manganese sulphate (BP)...0.5mg
                                        Chromium(as  chromium Picolinate)(USP)...12 mcg
                                        Potassium  Chloride (BP)...100mg
                                        Beta Carotene (BP)...380mcg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Cranman-C Sachei</td>
                                    <td>
                                        Each Sachet contain:
                                        Cranberry Extract (USP)...300 mg
                                        D-Mannose (MS)...250 mg
                                        Vitamin C  (USP)...30 mg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Crancit Sachet</td>
                                    <td>
                                        Each Sachet contain:
                                        Cranberry  Extract (USP)…144mg</td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Rofo Sachet</td>
                                    <td>
                                        Each sachet contains:
                                        Iron as Ferrous Fumarate (USP)…18mg
                                        Folic acid (USP)...0.4mg
                                        Vitamin B6 (USP)…1.4mg
                                        Vitamin B12 (USP)…5mcg
                                        Vitamin C (USP)…80mg
                                    </td>
                                    <td>1's
                                        5's
                                        10's
                                        20's
                                        30's
                                        50's
                                        100's</td>
                                </tr>
                                <tr>
                                    <td>Spraincit Cream</td>
                                    <td>
                                        Each tube contains:
                                        Wintergreen oil (BP)...12.50 %
                                        Camphor (BP)...3 %
                                        Menthol (BP)...2 %
                                        Eucalyptus oil (BP)…1.50 %</td>
                                    <td>50gm</td>
                                </tr>
                                <tr>
                                    <td>Scarcit Gel</td>
                                    <td>
                                        Each 25g tube contains:
                                        Tea Tree Oil (BP)...0.004g
                                        Lemon oil (BP)...0.0001g
                                        Rasoot Distillate (MS)...0. 1g
                                        Orange Peel Ext. (MS)...0. 1g
                                        Rose Distillate (BP)...0.1g
                                        Ginger Extract (BP)...0.0005g</td>
                                    <td>25gm
                                        50gm
                                        75gm
                                        100gm</td>
                                </tr>
                                <tr>
                                    <td>Aquaphil Lotion</td>
                                    <td>
                                        Each ml contains:
                                        Prunus Armeniaca Kernel Oil
                                        (MS)...70mg
                                        Aloe Barbadensis Leaf Juice
                                        (USP)...2mg</td>
                                    <td>25gm
                                        50gm
                                        75gm
                                        100gm
                                        150gm
                                    </td>
                                </tr>
                                <tr>
                                    <td>Artemisia  Ointment</td>
                                    <td>
                                        Each 25gm contains:
                                        Artemisia Oil (BP)...5 gm
                                        Wintergreen  Oil (BP)...1.25gm
                                        Thymol Ext. (USP)...0.25gm
                                        Capsaicin Oil  (USP)...0.013gm
                                        Turmerie extract (BP).0.125gm
                                        Camphor  (USP)...2.5gm
                                        Menthol ( USP)…1.25gm</td>
                                    <td>25gm
                                        50gm
                                        75gm
                                        100gm</td>
                                </tr>
                                <tr>
                                    <td>Clovecit  Balm</td>
                                    <td>
                                        Each gram contains:
                                        Camphor (USP)...6.0%
                                        Turpentine oil (BP)...4.0%
                                        Peppermint oil (USP)...3.0%
                                        Clove oil  (UPI)…1.5%
                                        Eucalyptus Oil  (BP)…1.5%
                                        Thyme oil (BP)...0.1%</td>
                                    <td>25gm
                                        50gm
                                        75gm
                                        100gm
                                        150gm
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </Layout>
    )
}
