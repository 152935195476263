import React from 'react'
import { Link, NavLink } from "react-router-dom";
import scrollToTop from '../scrollToTop';
import SearchBar from './SearchBar';

export default function Navbar() {

    return (
        <>
            <header>
                <div className="px-4 pt-2 d-flex align-items-center justify-content-end">
                    <span className='fw-medium text-uppercase me-1'>Search </span> <SearchBar />
                </div>
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <Link to="/" className="navbar-brand fs-2 fw-bold text-white ms-2 ms-sm-3 ms-md-5" onClick={scrollToTop}>
                            <img src="../images/citi-logo.png" alt="Citi pharma logo" />
                        </Link>
                        <button className="navbar-toggler shadow-none navbar-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* <div className='search-icon'> */}
                        {/* <Link to="mailto:contact@citipharma.com.pk" className="btn border text-white text-uppercase fw-bold" title="Send an Email">
                                Contact us
                            </Link> */}
                        {/* <IoSearch />
                        </div> */}
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <div className="d-flex w-100 justify-content-start justify-content-lg-center">
                                <ul className="navbar-nav mb-2 mb-lg-0">
                                    {/* <li className="nav-item mx-2">
                                        <NavLink to="/" className="nav-link" onClick={scrollToTop}>Home</NavLink>
                                    </li> */}
                                    <li className="nav-item mx-2">
                                        <NavLink to="/about" className="nav-link" onClick={scrollToTop}>
                                            About Us
                                        </NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/finish-products" className="nav-link" onClick={scrollToTop}>Our Products
                                        </NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/citi-natural" className="nav-link" onClick={scrollToTop}>Nutraceuticals</NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/research-development" className="nav-link" onClick={scrollToTop}>R&D</NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/api-manufacturer" className="nav-link" onClick={scrollToTop}>API Manufacturer</NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/biotech" className="nav-link" onClick={scrollToTop}>Biologics</NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/csr" className="nav-link" onClick={scrollToTop}>CSR</NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/blogs" className="nav-link" onClick={scrollToTop}>Blogs</NavLink>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink to="/contact" className="nav-link contact-btn" onClick={scrollToTop}>Contact Us</NavLink>
                                    </li>
                                    {/* <li className='nav-item mx-2 cursor-pointer'>
                                        Investors
                                        <span>
                                            <NavLink to="/pattern-shareholding" className="nav-link py-0 fs-6" onClick={scrollToTop}>Pattern of Shareholding</NavLink>
                                            <NavLink to="/proxy-form" className="nav-link py-0 fs-6" onClick={scrollToTop}>Proxy Form</NavLink>
                                            <NavLink to="/balloting" className="nav-link py-0 fs-6" onClick={scrollToTop}>Balloting</NavLink>
                                            <NavLink to="/annual-report-2024" className="nav-link py-0 fs-6" onClick={scrollToTop}>Annaul Report 2024</NavLink>
                                            <NavLink to="/annual-report" className="nav-link py-0 fs-6" onClick={scrollToTop}>Annaul Report 2023</NavLink>
                                            <NavLink to="/first-quaterly-report" className="nav-link py-0 fs-6" onClick={scrollToTop}>1st Quaterly Report</NavLink>
                                            <NavLink to="/third-quaterly-report" className="nav-link text-white py-0 fs-6" onClick={scrollToTop}>3rd Quaterly Report</NavLink>
                                            <NavLink to="/notices" className="nav-link py-0 fs-6" onClick={scrollToTop}>Notices</NavLink>
                                        </span>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
