import React from 'react'
import Layout from './Layout'
import pdfFile from '../../assets/CSR-REPORT.pdf'

export default function CSRReport() {
    return (
        <>
            <Layout title={"Citi Pharma: CSR Report"} description={"Access Citi Pharma's CSR Report."} keywords={"csr report, Citi Pharma"}>

                <div className='pdf'>
                    <h1 className='mainHeadings mt-5'>CSR Report</h1>
                    <iframe src={pdfFile} title="Annual Report 2023" className='h-100 w-100' />
                </div>

            </Layout >
        </>
    )
}
