import React, { useEffect, useState } from 'react'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import scrollToTop from '../scrollToTop';
import Feedback from '../Feedback';

export default function TopFooter() {

    const [isVisible, setIsVisible] = useState(false);
    // const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (window.scrollY > 400) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Dropdown
    // const handleMouseEnter = () => {
    //     setIsOpen(true);
    // };

    // const handleMouseLeave = () => {
    //     setIsOpen(false);
    // };

    return (
        <>
            <div className='topFooter'>
                <div style={{ display: isVisible ? 'block' : 'none' }}>
                    <Feedback />
                </div>
                <div className="topFooterRow">
                    <div className="topFooterCol">
                        <h1>Company</h1>
                        <ul>
                            <Link to="/about" className="nav-link" onClick={scrollToTop}>About Us</Link>
                            <Link to="/contact" className="nav-link" onClick={scrollToTop}>Contact Us</Link>
                            <Link to="/research-development" className="nav-link" onClick={scrollToTop}>R & D</Link>
                            <Link to="/biotech" className="nav-link" onClick={scrollToTop}>Biologics</Link>
                            <Link to="/csr" className="nav-link" onClick={scrollToTop}>CSR</Link>
                            <Link to="/careers" className="nav-link" onClick={scrollToTop}>Careers</Link>
                            <Link to="/" className="nav-link" onClick={scrollToTop}>Media</Link>
                        </ul>
                    </div>
                    <div className="topFooterCol">
                        <h1>Investors</h1>
                        <ul>
                            <Link to="/annual-report-2024" className="nav-link" onClick={scrollToTop}>Annual Report 2024</Link>
                            <Link to="/annual-report" className="nav-link" onClick={scrollToTop}>Annual Report 2023</Link>
                            <Link to="/impact-report" className="nav-link" onClick={scrollToTop}>Impact Report</Link>
                            <Link to="/pattern-shareholding" className="nav-link" onClick={scrollToTop}>Pattern of Shareholding</Link>
                            <Link to="/proxy-form" className="nav-link" onClick={scrollToTop}>Proxy Form</Link>
                            <Link to="/balloting" className="nav-link" onClick={scrollToTop}>Balloting</Link>
                            {/* <Link to="/annual-report-2024" className="nav-link py-0 fs-6" onClick={scrollToTop}>Annaul Report 2024</Link>
                            <Link to="/annual-report" className="nav-link py-0 fs-6" onClick={scrollToTop}>Annaul Report 2023</Link> */}
                            {/* <Link to="/first-quaterly-report" className="nav-link py-0 fs-6" onClick={scrollToTop}>1st Quaterly Report</Link>
                            <Link to="/third-quaterly-report" className="nav-link text-white py-0 fs-6" onClick={scrollToTop}>3rd Quaterly Report</Link> */}
                            <Link to="/notices" className="nav-link" onClick={scrollToTop}>Notices</Link>
                        </ul>
                    </div>
                    <div className="topFooterCol">
                        <h1>Explore</h1>
                        <ul>
                            <Link to="/finish-products" className="nav-link" onClick={scrollToTop}>Our Products</Link>
                            <Link to="/api-manufacturer" className="nav-link" onClick={scrollToTop}>API Manufacturer</Link>
                            <Link to="/citi-natural" className="nav-link" onClick={scrollToTop}>Citi Natural</Link>
                            <Link to="/blogs" className="nav-link" onClick={scrollToTop}>Blogs</Link>
                            <Link to="/pipeline" className="nav-link" onClick={scrollToTop}>Pipeline</Link>
                            {/* <li className='nav-link' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                Products
                                <span className={isOpen ? 'block' : 'hidden'}>
                                    <Link to="/api-manufacturer" className="nav-link py-1 px-2" onClick={scrollToTop}>Api Manufacturer</Link>
                                    <Link to="/citi-natural" className="nav-link py-1 px-2" onClick={scrollToTop}>Citi Natural</Link>
                                </span>
                            </li> */}
                            <Link to="/privacy-policy" className="nav-link" onClick={scrollToTop}>Privacy Policy</Link>
                            <Link to="/terms-and-conditions" className="nav-link" onClick={scrollToTop}>Terms & Conditions</Link>
                        </ul>
                    </div>
                    <div className="topFooterCol">
                        <h1>Contact Us</h1>
                        <ul>
                            <li className='pb-1'>588 Block Q, Phase 2 Johar Town, Lahore, Punjab 54000</li>
                            {/* <li className='pb-1'>+92 4235316587</li> */}
                            <li className='pb-1'>contact@citipharma.com.pk</li>
                            <div className="topFooterIcons">
                                <FaInstagram className='icons' onClick={() => window.open("https://www.instagram.com/citi_pharmaa/?igsh=MXQwajB2MzNxbTI3Mw%3D%3D", "_blank")} />
                                <FaFacebook className='icons' onClick={() => window.open("https://www.facebook.com/citipharmaltd?mibextid=ZbWKwL", "_blank")} />
                                <FaLinkedin className='icons' onClick={() => window.open("https://www.linkedin.com/company/citipharmalimited/", "_blank")} />
                            </div>
                        </ul>
                    </div>
                </div>
                {/* <div className="topFooterIcons" style={{ display: isVisible ? 'flex' : 'none' }}>
                    <FaInstagram className='icons' onClick={() => window.open("https://www.instagram.com/citi_pharmaa/?igsh=MXQwajB2MzNxbTI3Mw%3D%3D", "_blank")} />
                    <FaFacebook className='icons' onClick={() => window.open("https://www.facebook.com/citipharmaltd?mibextid=ZbWKwL", "_blank")} />
                    <FaLinkedin className='icons' onClick={() => window.open("https://www.linkedin.com/company/citipharmalimited/", "_blank")} />
                </div> */}
            </div>
        </>
    )
}
