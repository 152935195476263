import React from "react";
import Layout from "./Layout";

const TermsAndConditions = () => {
    return (
        <Layout
            title={'Terms and Conditions - Citi Pharma Pvt Ltd'}
            description={"Explore Citi Pharma's Terms and Conditions. Learn about the usage rules, responsibilities, and legal aspects of using our website and services."}
            keywords={"Citi Pharma, terms and conditions, website usage, legal terms, user agreement, service terms"}
        >
            <div className="container">
                <h1 className="mb-4 mainHeadings">Terms and Conditions</h1>
                <div>
                    <div>
                        <p className="lead">
                            Welcome to Citi Pharma. Please read these Terms and Conditions carefully before using our services.
                        </p>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Acceptance of Terms</h3>
                            <p>
                                By accessing or using the Citi Pharma website and services, you agree to comply with these Terms and Conditions. If you do not agree to any part of these terms, you must not use our services.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Modification of Terms</h3>
                            <p>
                                Citi Pharma reserves the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on this page. We encourage you to review these terms periodically.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Use of Our Services</h3>
                            <p>
                                You agree to use the Citi Pharma website and services for lawful purposes only. You may not:
                            </p>
                            <ul>
                                <li>Engage in any activity that violates local or international laws.</li>
                                <li>Impersonate any person or entity or falsely represent your affiliation with any person or entity.</li>
                                <li>Distribute malware or attempt to interfere with the functioning of our services.</li>
                            </ul>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Account Responsibilities</h3>
                            <p>
                                If you create an account with Citi Pharma, you are responsible for maintaining the confidentiality of your account credentials. You must notify us immediately if you suspect any unauthorized access or use of your account.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Payment and Fees</h3>
                            <p>
                                Certain services provided by Citi Pharma may require payment. All payments must be made in accordance with the pricing terms provided at the time of purchase. Citi Pharma reserves the right to modify the pricing and fees at any time.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Intellectual Property</h3>
                            <p>
                                All content on the Citi Pharma website, including text, images, logos, and software, is the property of Citi Pharma or its content suppliers and is protected by copyright laws. You may not use or distribute any content from our website without our prior written permission.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Third-Party Links</h3>
                            <p>
                                Our website may contain links to external sites. Citi Pharma is not responsible for the content, privacy practices, or policies of any third-party websites. You access these sites at your own risk.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Limitation of Liability</h3>
                            <p>
                                Citi Pharma is not liable for any direct, indirect, incidental, special, or consequential damages resulting from your use or inability to use our services or any third-party sites linked to our website.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Termination</h3>
                            <p>
                                Citi Pharma reserves the right to suspend or terminate your access to the website and services at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users or our services.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Indemnification</h3>
                            <p>
                                You agree to indemnify, defend, and hold harmless Citi Pharma, its officers, directors, employees, and agents from any claim, loss, or damage arising from your breach of these Terms and Conditions or your use of the website and services.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Governing Law</h3>
                            <p>
                                These Terms and Conditions shall be governed by and construed in accordance with the laws of Pakistan. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts in Lahore, Pakistan.
                            </p>
                        </section>

                        <section className="mb-4">
                            <h3 className="privacy-policy-headings">Contact Us</h3>
                            <p>
                                If you have any questions or concerns about these Terms and Conditions, please contact us at:
                            </p>
                            <ul>
                                <li>Email: info@citipharma.com.pk</li>
                                <li>Phone: +92-123-456789</li>
                                <li>Address: Citi Pharma Pvt Ltd, Lahore, Pakistan</li>
                            </ul>
                        </section>

                        <p className="text-center">
                            Thank you for using Citi Pharma! Your trust in our services is valuable to us.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default TermsAndConditions;
